export const TAG_NAME = "gen-rma";
export class RichMediaAd extends HTMLElement {
  static get cookieName() {
    return "richMediaAdsOk";
  }

  hide() {
    this.setAttribute("hidden", "");
  }

  unveil() {
    this.classList.add("rich-media--unveiled");
  }

  handleEvent(event) {
    try {
      let name = `handle${event.type.charAt(0).toUpperCase()}${event.type.slice(1)}`;
      // Handle `bad:*`-events
      name = name.replace(/Bad:([a-z]{1})/, (match, p1) => p1.toUpperCase());
      return this[name](event);
    } catch {
      return;
    }
  }

  handleCollapse() {
    this.hide();
  }

  handleClick(event) {
    if (event.target.closest(".rich-media__button")) {
      this.hide();
    }
  }

  handleSlotRenderEnded() {
    clearTimeout(this.failsafe);
    this.unveil();
  }

  connectedCallback() {
    this.addEventListener("click", this);
    this.addEventListener("bad:collapse", this);
    this.addEventListener("bad:slotRenderEnded", this);

    if (window.generika && window.generika.alwaysShowRichMediaAd) {
      this.unveil();
      return;
    } else {
      this.failsafe = setTimeout(() => {
        this.unveil();
      }, 400);
    }

    const s = new URLSearchParams(window.location.search);
    const isAdDemo = s.has("google_preview") || s.has("ad_demo");
    if (!isAdDemo && (document.cookie.includes("bad-freq-rmp") || !document.cookie.includes(RichMediaAd.cookieName))) {
      this.hide();
    }
  }
}
